import mixpanel from 'mixpanel-browser/src/loaders/loader-module-core';
import { useSelector } from 'react-redux';

export const ADD_TO_CART = 'ADD_TO_CART';
export const INCREASE_QUANTITY = 'INCREASE_QUANTITY';
export const DECREASE_QUANTITY = 'DECREASE_QUANTITY';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const SET_PAYMENT_DETAILS = 'SET_PAYMENT_DETAILS';
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD';
export const SET_COMPLETED_ORDER = 'SET_COMPLETED_ORDER';
export const RESET_COMPLETED_ORDER = 'RESET_COMPLETED_ORDER';
export const REST_CART = 'REST_CART';
export const SET_CART_TYPE = 'SET_CART_TYPE';
export const RESET_CART_TYPE = 'RESET_CART_TYPE';
export const SET_CART_DIALOG = 'SET_CART_DIALOG';
export const PURCHASE_LIMIT_SUCCESS = 'PURCHASE_LIMIT_SUCCESS';
export const PURCHASE_LIMIT_FAILURE = 'PURCHASE_LIMIT_FAILURE';
export const SET_PURCHASE_LIMIT_LOADING = 'SET_PURCHASE_LIMIT_LOADING';
export const RESET_PURCHASE_LIMIT_STATE = 'RESET_PURCHASE_LIMIT_STATE';

export const saveProductInMixpanel = async (item) => {
  try {
    mixpanel.track('Product Added to Cart', {
      id: item.productId,
      ...(item?.thumbnail ?? {}),
      ...(item?.experience ?? {}),
      ...(item.composition ?? {}),
      price: item.quantitySelection.price,
      weight: item.quantitySelection.weight,
      quantity: item.quantitySelection.quantity
    });
  } catch (error) {
    console.log(error);
  }
};

export const addToCart = (item) => {
  saveProductInMixpanel(item);
  console.log("addToCart",{item})
  return {
    type: ADD_TO_CART,
    payload: {
      id: item.productId,
      name: item.thumbnail.name,
      weight: item.quantitySelection.weight,
      price: item.quantitySelection.price,
      quantity: item.quantitySelection.quantity,
      img: item.thumbnail.picture,
      productType: item?.composition?.cannabinoids?.productType,
      isMedical: Object.keys(item?.medicinal ?? {}).length > 0
      
    }
  };
};

export const removeFromCart = (id) => ({
  type: REMOVE_FROM_CART,
  payload: id
});

export const increaseQuantity = (id) => ({
  type: INCREASE_QUANTITY,
  payload: id
});

export const decreaseQuantity = (id) => ({
  type: DECREASE_QUANTITY,
  payload: id
});

export const setPaymentDetails = (paymentDetails) => ({
  type: SET_PAYMENT_DETAILS,
  payload: paymentDetails
});

export const setPaymentMethod = (method) => ({
  type: SET_PAYMENT_METHOD,
  payload: method
});

export const setCompletedOrder = (order) => ({
  type: SET_COMPLETED_ORDER,
  payload: order
});

export const resetCompletedOrder = () => ({
  type: RESET_COMPLETED_ORDER
});

export const resetCartItems = () => ({
  type: REST_CART
});

export const setCartType = (cartType) => ({
  type: SET_CART_TYPE,
  payload: cartType
});

export const resetCartType = () => ({
  type: RESET_CART_TYPE
});

export const setCartDialog = (dialogState) => ({
  type: SET_CART_DIALOG,
  payload: dialogState
});

export const useCheckoutPayload = () => {
  const checkoutState = useSelector((state) => state.checkout);
  const { items, paymentDetails } = checkoutState;

  const apiPayload = {
    user: {
      firstName: paymentDetails.firstName,
      lastName: paymentDetails.lastName,
      email: paymentDetails.emailAddress,
      birthday: paymentDetails.birthday,
      phoneNumber: paymentDetails.phoneNumber
    },
    items: items.map((item) => ({
      productId: item.id,
      quantity: item.quantity
    })),
    shipping: {
      method: 'Standard',
      address: '123 Main St, Some City, Some Country',
      cost: 5.99
    },
    paymentMethod: paymentDetails.paymentMethod,
    totalAmount: items.reduce((total, item) => total + item.price * item.quantity, 0),
    discount: 0,
    tax: 0.07,
    orderType: 'Online',
    comments: 'Please deliver after 5 pm.',
    pickUpTimeFrame: paymentDetails.pickupTime,
    medicalId: paymentDetails.medicalId,
    registrationDate: paymentDetails.registrationDate,
    expiryDate: paymentDetails.expirationDate
  };

  return apiPayload;
};

export const purchaseLimitSuccess = (message) => ({
  type: PURCHASE_LIMIT_SUCCESS,
  payload: message
});

export const purchaseLimitFailure = (error) => ({
  type: PURCHASE_LIMIT_FAILURE,
  payload: error
});

export const setPurchaseLimitLoading = (loading) => ({
  type: SET_PURCHASE_LIMIT_LOADING,
  payload: loading
});

export const resetPurchaseLimitState = () => ({
  type: RESET_PURCHASE_LIMIT_STATE
});
