import React, { useEffect, useState } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';

// Mui
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, Grid, IconButton, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router';
import { NavigationType } from '../../consts/app_enums';
import AppLayout from '../../layouts/appLayout/appLayout';
import { selectCheckoutProducts } from '../../store/checkout/selectors/checkoutSelectors';
import { selectProductData, selectProductDetails } from '../../store/productDetails/selectors/productSelectors';
import RecommendedProducts from '../ProductDetails/Components/RecommendedProducts/RecommendedProducts';
import OrderSummaryCard from './Components/OrderSummary';
import ProductCard from './Components/ProductCard';

import { resetCartItems } from '../../store/checkout/actions/checkoutActions';
import { useFilterSelection } from '../../hooks/useFilterSelection';

const StyledTypographyBodyQuestion = styled(Typography)(() => ({
  fontFamily: 'Inter',
  fontSize: '20px',
  fontWeight: '700',
  color: 'black',
  marginTop: '3%',
  marginBottom: '10%'
}));

const StyledButton = styled(Button)(() => ({
  backgroundColor: 'black',
  border: '3px solid transparent',
  borderImage: 'linear-gradient(to bottom right, #b827fc 0%, #2c90fc 25%, #b8fd33 50%, #fec837 75%, #fd1892 100%)',
  borderImageSlice: 1,
  width: '100%',
  '&:hover': {
    backgroundColor: 'black'
  },
  '&:disabled': {
    backgroundColor: '#767676'
  },
  color: 'white'
}));

const ClearCardPopup = ({ dialogOpen, onClose, dispatch }) => {
  return (
    <Dialog
      open={dialogOpen}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '450px'
          }
        }
      }}
      fullWidth
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'right',
          paddingRight: '10px'
        }}
      >
        <IconButton
          color="inherit"
          style={{ outline: 'none' }}
          sx={{
            '&:hover': {
              backgroundColor: '#fff'
            }
          }}
          onClick={() => {
            onClose();
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <Paper
        elevation={0}
        sx={{
          marginTop: '3%',
          padding: 2,
          width: { xs: '90%', sm: '486px' },
          height: 170,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <StyledTypographyBodyQuestion>Are you sure you want to clear your cart?</StyledTypographyBodyQuestion>

        <Grid container spacing={2} justifyContent={'space-between'}>
          <Grid item xs={6}>
            <StyledButton
              onClick={() => {
                dispatch(resetCartItems());
                onClose();
              }}
            >
              YES
            </StyledButton>
          </Grid>

          <Grid item xs={6}>
            <StyledButton onClick={() => onClose()}>NO</StyledButton>
          </Grid>
        </Grid>
      </Paper>
    </Dialog>
  );
};

const Checkout = () => {
  const navigate = useNavigate();
  const { handleResetFilters } = useFilterSelection();

  // Redux
  const dispatch = useDispatch();
  const productData = useSelector(selectProductData);
  const productDetails = useSelector(selectProductDetails);
  const checkoutProducts = useSelector(selectCheckoutProducts);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [clearCartPopup, setClearCartPopup] = useState(false);

  console.log({productDetails, checkoutProducts})

  const handleProductClick = (id) => {
    navigate(`/product/${id}`);
  };

  const onClose = () => {
    setClearCartPopup(false);
  };

  useEffect(() => {
    handleResetFilters(); // Reset previous products page filters
  }, []);

  return (
    <AppLayout version={NavigationType.V3} title={'My Cart'}>
      <Paper
        elevation={0}
        sx={{
          minHeight: '100vh'
        }}
      >
        <Box sx={{ margin: 'auto', p: isMobile ? 0 : 4, maxWidth: isMobile ? '100%' : '80%' }}>
          <Grid container>
            <Grid item xs={12} md={8}>
              {checkoutProducts.map((product, index) => {
                return <ProductCard key={index} productInfo={product} showMedicalLabel={true} onClick={handleProductClick} />;
              })}

              {checkoutProducts?.length > 0 && (
                <Button style={{ color: '#000', textDecoration: 'underline', fontSize: '16px' }} onClick={() => setClearCartPopup(true)}>
                  Clear Cart
                </Button>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <OrderSummaryCard />
            </Grid>
          </Grid>
        </Box>

        <Grid container spacing={0} px={4}>
          {productData ? (
            <RecommendedProducts
              title="Recommended Products"
              products={productData.recommended}
              isAnalyzed={productData.isPowerleafAnalyzed}
              isCheckout
            />
          ) : (
            <></>
          )}
        </Grid>
        <ClearCardPopup dialogOpen={clearCartPopup} onClose={onClose} dispatch={dispatch} />
      </Paper>
    </AppLayout>
  );
};

export default Checkout;
