import { Box, Divider, Grid, Typography } from '@mui/material';
import { ReactComponent as Logo2 } from '../../../../assets/icons/navigationBar/logo_2.svg';
import { ReactComponent as ReportAnaylzedByLogo } from '../../../../assets/icons/navigationBar/report_analyzed_by.svg';
import CardBackBackground from '../../../../assets/images/miniReport/CardBackBackground.png';
import CardBackBottomBackground from '../../../../assets/images/miniReport/CardBackBottomBackground.png';
import { getMoodColors } from '../../../../config/consts';
import { ActivitiesLogosSVG } from '../../../../utils/activitiesUtil';
import { AromaLogosSVG } from '../../../../utils/aromaUtil';
import { MedicinalLogosSVG } from '../../../../utils/medicinalUtil';
import Cannabinoids from '../../../ProductDetails/Components/ProductComposition/Cannabinoids';
import Terpenes from '../../../ProductDetails/Components/ProductComposition/Terpenes';
import MoodsComponent from '../../../ProductDetails/Components/ProductExperience/ProductMoods';

const StressSVG = ({ chemoName }) => {
  const color = getMoodColors(chemoName).color;

  return (
    <svg width="20" height="20" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M41 22.2002C41 32.4175 32.7173 40.7002 22.5 40.7002C12.2827 40.7002 4 32.4175 4 22.2002C4 11.9829 12.2827 3.7002 22.5 3.7002C32.7173 3.7002 41 11.9829 41 22.2002Z"
        stroke={color}
      />
      <path
        d="M17.5 22.7002C19.16 22.7002 20.5 21.3602 20.5 19.7002C20.5 18.0402 19.16 16.7002 17.5 16.7002C15.84 16.7002 14.5 18.0402 14.5 19.7002C14.5 21.3602 15.84 22.7002 17.5 22.7002ZM29.5 16.7002H21.5V23.7002H13.5V14.7002H11.5V29.7002H13.5V26.7002H31.5V29.7002H33.5V20.7002C33.5 18.4902 31.71 16.7002 29.5 16.7002Z"
        fill={color}
      />
    </svg>
  );
};

const IntimacySVG = ({ chemoName }) => {
  const color = getMoodColors(chemoName).color;

  return (
    <svg width="20" height="20" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M41 22.6002C41 32.8175 32.7173 41.1003 22.5 41.1003C12.2827 41.1003 4 32.8175 4 22.6002C4 12.383 12.2827 4.10022 22.5 4.10022C32.7173 4.10022 41 12.383 41 22.6002Z"
        stroke={color}
      />
      <path
        d="M15.5553 27.0783L22.4091 33.7669L29.3042 27.0783C30.1575 26.2525 30.8731 25.3373 31.4511 24.3326C32.0292 23.3279 32.3182 22.2338 32.3182 21.0502C32.3182 19.5363 31.7883 18.2495 30.7286 17.1898C29.6689 16.1301 28.3821 15.6002 26.8682 15.6002C25.9874 15.6002 25.1616 15.786 24.3909 16.1576C23.6202 16.5292 22.9596 17.0315 22.4091 17.6646C21.8586 17.0315 21.198 16.5292 20.4273 16.1576C19.6566 15.786 18.8308 15.6002 17.95 15.6002C16.4361 15.6002 15.1493 16.1301 14.0896 17.1898C13.0299 18.2495 12.5 19.5363 12.5 21.0502C12.5 22.2338 12.7959 23.3279 13.3877 24.3326C13.9795 25.3373 14.702 26.2525 15.5553 27.0783Z"
        fill={color}
      />
    </svg>
  );
};

const MindfullnessSVG = ({ chemoName }) => {
  const color = getMoodColors(chemoName).color;
  return (
    <svg width="20" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.96229 1.29556C10.1398 1.19309 10.3584 1.19309 10.5359 1.29556L17.644 5.39944C17.8215 5.50191 17.9308 5.69127 17.9308 5.89621V14.104C17.9308 14.3089 17.8215 14.4983 17.644 14.6007L10.5359 18.7046C10.3584 18.8071 10.1398 18.8071 9.96229 18.7046L2.85417 14.6007C2.67669 14.4983 2.56736 14.3089 2.56736 14.104V5.89621C2.56736 5.69127 2.67669 5.5019 2.85417 5.39944L9.96229 1.29556Z"
        stroke={color}
        strokeWidth="0.382413"
      />
      <path
        d="M7.95871 10.1969C8.64466 10.1969 9.19838 9.66905 9.19838 9.01511C9.19838 8.36117 8.64466 7.83329 7.95871 7.83329C7.27276 7.83329 6.71904 8.36117 6.71904 9.01511C6.71904 9.66905 7.27276 10.1969 7.95871 10.1969ZM12.9174 7.83329H9.6116V10.5909H6.30582V7.04541H5.47937V12.9545H6.30582V11.7727H13.7438V12.9545H14.5703V9.40905C14.5703 8.53844 13.8306 7.83329 12.9174 7.83329Z"
        fill={color}
      />
    </svg>
  );
};

const SleepSVG = ({ chemoName }) => {
  const color = getMoodColors(chemoName).color;

  return (
    <svg width="20" height="20" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M41 22.2002C41 32.4175 32.7173 40.7002 22.5 40.7002C12.2827 40.7002 4 32.4175 4 22.2002C4 11.9829 12.2827 3.7002 22.5 3.7002C32.7173 3.7002 41 11.9829 41 22.2002Z"
        stroke={color}
      />
      <path
        d="M17.5 22.7002C19.16 22.7002 20.5 21.3602 20.5 19.7002C20.5 18.0402 19.16 16.7002 17.5 16.7002C15.84 16.7002 14.5 18.0402 14.5 19.7002C14.5 21.3602 15.84 22.7002 17.5 22.7002ZM29.5 16.7002H21.5V23.7002H13.5V14.7002H11.5V29.7002H13.5V26.7002H31.5V29.7002H33.5V20.7002C33.5 18.4902 31.71 16.7002 29.5 16.7002Z"
        fill={color}
      />
    </svg>
  );
};

const RelaxSVG = ({ chemoName }) => {
  const color = getMoodColors(chemoName).color;

  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.96327 1.29531C10.1407 1.19284 10.3594 1.19284 10.5369 1.29531L17.645 5.39919C17.8225 5.50166 17.9318 5.69103 17.9318 5.89596V14.1037C17.9318 14.3087 17.8225 14.498 17.645 14.6005L10.5369 18.7044C10.3594 18.8068 10.1407 18.8068 9.96327 18.7044L2.85514 14.6005C2.67767 14.498 2.56834 14.3087 2.56834 14.1037V5.89596C2.56834 5.69103 2.67767 5.50166 2.85515 5.39919L9.96327 1.29531Z"
        stroke={color}
        strokeWidth="0.382413"
      />
      <path
        d="M10.2475 14.5455C9.61869 14.5455 9.02778 14.4262 8.47475 14.1875C7.92172 13.9489 7.44066 13.625 7.03157 13.216C6.62248 12.8069 6.29862 12.3258 6.05998 11.7728C5.82134 11.2197 5.70203 10.6288 5.70203 10C5.70203 9.37126 5.82134 8.78035 6.05998 8.22732C6.29862 7.67429 6.62248 7.19323 7.03157 6.78414C7.44066 6.37504 7.92172 6.05118 8.47475 5.81254C9.02778 5.57391 9.61869 5.45459 10.2475 5.45459C10.8763 5.45459 11.4672 5.57391 12.0202 5.81254C12.5732 6.05118 13.0543 6.37504 13.4634 6.78414C13.8725 7.19323 14.1963 7.67429 14.435 8.22732C14.6736 8.78035 14.7929 9.37126 14.7929 10C14.7929 10.6288 14.6736 11.2197 14.435 11.7728C14.1963 12.3258 13.8725 12.8069 13.4634 13.216C13.0543 13.625 12.5732 13.9489 12.0202 14.1875C11.4672 14.4262 10.8763 14.5455 10.2475 14.5455ZM8.1793 10.2728L9.8043 9.09095L8.1793 7.90914L7.77021 8.45459L8.64521 9.09095L7.77021 9.72732L8.1793 10.2728ZM9.56566 12.2955L10.2475 11.6137L10.9293 12.2955L11.6111 11.6137L12.0543 12.0569L12.5316 11.5796L11.6111 10.6591L10.9293 11.341L10.2475 10.6591L9.56566 11.341L8.88384 10.6591L7.96339 11.5796L8.44066 12.0569L8.88384 11.6137L9.56566 12.2955ZM12.3157 10.2728L12.7248 9.72732L11.8498 9.09095L12.7248 8.45459L12.3157 7.90914L10.6907 9.09095L12.3157 10.2728Z"
        fill={color}
      />
    </svg>
  );
};

const CreativitySVG = ({ chemoName }) => {
  const color = getMoodColors(chemoName).color;

  return (
    <svg width="20" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.96315 1.29556C10.1406 1.19309 10.3593 1.19309 10.5368 1.29556L17.6449 5.39944C17.8224 5.50191 17.9317 5.69127 17.9317 5.89621V14.104C17.9317 14.3089 17.8224 14.4983 17.6449 14.6007L10.5368 18.7046C10.3593 18.8071 10.1406 18.8071 9.96315 18.7046L2.85502 14.6007C2.67754 14.4983 2.56821 14.3089 2.56821 14.104V5.89621C2.56821 5.69127 2.67754 5.5019 2.85502 5.39944L9.96315 1.29556Z"
        stroke={color}
        strokeWidth="0.382413"
      />
      <path
        d="M12.3951 6.61118L13.5517 5.45459H9.51123V9.49507L10.6729 8.33343C11.6678 9.07082 12.3143 10.2426 12.3143 11.5759C12.3143 12.2375 12.1527 12.8588 11.8699 13.4093C13.0467 12.6416 13.8295 11.3183 13.8295 9.80821C13.8295 8.53546 13.2689 7.39907 12.3951 6.61118Z"
        fill={color}
      />
      <path
        d="M7.72209 8.42419C7.72209 7.76256 7.88371 7.14134 8.16654 6.59082C6.9847 7.35851 6.20691 8.68177 6.20691 10.1919C6.20691 11.4646 6.76248 12.601 7.64128 13.3889L6.47964 14.5455H10.5201V10.505L9.35848 11.6667C8.36857 10.9293 7.72209 9.75755 7.72209 8.42419Z"
        fill={color}
      />
    </svg>
  );
};

const ProductExperienceLogoSleep = ({ chemoName }) => {
  const color = getMoodColors(chemoName).color;
  return (
    <svg width="26" height="26" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="35" cy="35" r="34.0921" fill={color} stroke={color} strokeWidth="1.81579" />
      <path
        d="M25.716 35.9204C28.7652 35.9204 31.2265 33.452 31.2265 30.3941C31.2265 27.3362 28.7652 24.8678 25.716 24.8678C22.6668 24.8678 20.2054 27.3362 20.2054 30.3941C20.2054 33.452 22.6668 35.9204 25.716 35.9204ZM47.7583 24.8678H33.0634V37.7625H18.3685V21.1836H14.6948V48.8151H18.3685V43.2888H51.432V48.8151H55.1057V32.2362C55.1057 28.1652 51.8177 24.8678 47.7583 24.8678Z"
        fill="white"
      />
    </svg>
  );
};

const ProductExperienceLogoRelax = ({ chemoName }) => {
  const color = getMoodColors(chemoName).color;
  return (
    <svg width="26" height="26" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="25" cy="25" r="24.3515" fill="#007FFF" stroke="#007FFF" strokeWidth="1.29699" />
      <path
        d="M35.8147 22.9704C34.559 22.9704 33.5317 23.9978 33.5317 25.2535V28.6781H17.5501V25.2535C17.5501 23.9978 16.5341 22.9704 15.267 22.9704C13.9999 22.9704 12.9839 23.9978 12.9839 25.2535V30.9612C12.9839 32.2169 14.0113 33.2443 15.267 33.2443H35.8147C37.0704 33.2443 38.0978 32.2169 38.0978 30.9612V25.2535C38.0978 23.9978 37.0704 22.9704 35.8147 22.9704ZM32.3901 17.2627H18.6916C17.4359 17.2627 16.4085 18.2901 16.4085 19.5458V22.0001C17.7327 22.4681 18.6916 23.7352 18.6916 25.2078V27.5366H32.3901V25.2192C32.3901 23.7352 33.349 22.4795 34.6732 22.0115V19.5458C34.6732 18.2901 33.6458 17.2627 32.3901 17.2627Z"
        fill="white"
      />
    </svg>
  );
};

const ProductExperienceLogoRecover = ({ chemoName }) => {
  const color = getMoodColors(chemoName).color;
  return (
    <svg width="26" height="26" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="25.5" cy="25.9531" r="24.3515" fill="#EB6E23" stroke="#EB6E23" strokeWidth="1.29699" />
      <path
        d="M31.7073 17.078L34.7365 14.0488H24.1543V24.631L27.1967 21.5887C29.8026 23.5199 31.4957 26.5887 31.4957 30.0809C31.4957 31.8137 31.0724 33.4407 30.3317 34.8825C33.4137 32.8719 35.464 29.4063 35.464 25.4512C35.464 22.1178 33.9957 19.1415 31.7073 17.078Z"
        fill="white"
      />
      <path
        d="M31.7073 17.078L34.7365 14.0488H24.1543V24.631L27.1967 21.5887C29.8026 23.5199 31.4957 26.5887 31.4957 30.0809C31.4957 31.8137 31.0724 33.4407 30.3317 34.8825C33.4137 32.8719 35.464 29.4063 35.464 25.4512C35.464 22.1178 33.9957 19.1415 31.7073 17.078Z"
        fill="white"
      />
      <path
        d="M19.4683 21.8261C19.4683 20.0933 19.8916 18.4662 20.6324 17.0244C17.5371 19.035 15.5 22.5007 15.5 26.4558C15.5 29.7892 16.9551 32.7654 19.2567 34.829L16.2143 37.8581H26.7965V27.2759L23.7541 30.3183C21.1615 28.3871 19.4683 25.3182 19.4683 21.8261Z"
        fill="white"
      />
      <path
        d="M19.4683 21.8261C19.4683 20.0933 19.8916 18.4662 20.6324 17.0244C17.5371 19.035 15.5 22.5007 15.5 26.4558C15.5 29.7892 16.9551 32.7654 19.2567 34.829L16.2143 37.8581H26.7965V27.2759L23.7541 30.3183C21.1615 28.3871 19.4683 25.3182 19.4683 21.8261Z"
        fill="white"
      />
    </svg>
  );
};

const ProductExperienceLogoIndulge = ({ chemoName }) => {
  const color = getMoodColors(chemoName).color;
  return (
    <svg width="26" height="26" viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="25" cy="25.4766" r="24.0921" fill="#654EA3" stroke="#654EA3" strokeWidth="1.81579" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.7443 37.2314L24.6431 38.922L26.5419 37.2314C28.7463 35.2592 30.5687 33.5578 32.0092 32.1274C33.4496 30.6969 34.5955 29.4128 35.4467 28.2749C36.2979 27.1371 36.8926 26.0913 37.2309 25.1377C37.5692 24.1841 37.7383 23.2088 37.7383 22.2118C37.7383 20.1745 37.0508 18.4731 35.6758 17.1077C34.3008 15.7423 32.5875 15.0596 30.536 15.0596C29.401 15.0596 28.3207 15.298 27.2949 15.7748C26.2691 16.2516 25.3852 16.9235 24.6431 17.7904C23.901 16.9235 23.0171 16.2516 21.9913 15.7748C20.9655 15.298 19.8852 15.0596 18.7502 15.0596C16.6986 15.0596 14.9854 15.7423 13.6104 17.1077C12.2354 18.4731 11.5479 20.1745 11.5479 22.2118C11.5479 23.2088 11.717 24.1841 12.0553 25.1377C12.3936 26.0913 12.9883 27.1371 13.8395 28.2749C14.3802 28.9977 15.0398 29.7795 15.8183 30.6204L21.0588 25.2729L23.923 28.1371L28.717 23.0908H26.4305V20.68H32.8679V27.1175H30.4572V24.7592L23.9672 31.5907L21.0761 28.6996L17.5004 32.3483C18.9044 33.732 20.6523 35.3597 22.7443 37.2314Z"
        fill="white"
      />
    </svg>
  );
};

const ProductExperienceLogoMotivate = ({ chemoName }) => {
  const color = getMoodColors(chemoName).color;
  return (
    <svg width="26" height="26" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="25" cy="25" r="24.3515" fill="#88B04B" stroke="#88B04B" strokeWidth="1.29699" />
      <path
        d="M23.69 32.8578C21.5075 32.8578 19.6523 32.094 18.1245 30.5662C16.5968 29.0384 15.8329 27.1832 15.8329 25.0007C15.8329 24.7606 15.8438 24.5205 15.8656 24.2805C15.8874 24.0404 15.9202 23.8003 15.9638 23.5602C15.8547 23.6039 15.7237 23.6366 15.571 23.6584C15.4182 23.6803 15.2872 23.6912 15.1781 23.6912C14.2614 23.6912 13.4866 23.3747 12.8537 22.7418C12.2208 22.1088 11.9043 21.334 11.9043 20.4174C11.9043 19.5007 12.2044 18.7259 12.8046 18.093C13.4048 17.46 14.1632 17.1436 15.0799 17.1436C15.8001 17.1436 16.4494 17.3454 17.0278 17.7492C17.6062 18.153 18.0154 18.6713 18.2555 19.3043C18.9757 18.6495 19.7996 18.1257 20.7272 17.7328C21.6548 17.34 22.6424 17.1436 23.69 17.1436H38.0948V22.3817H31.5472V25.0007C31.5472 27.1832 30.7833 29.0384 29.2555 30.5662C27.7277 32.094 25.8726 32.8578 23.69 32.8578ZM15.1781 21.7269C15.5491 21.7269 15.8601 21.6014 16.1111 21.3504C16.3621 21.0994 16.4876 20.7884 16.4876 20.4174C16.4876 20.0463 16.3621 19.7353 16.1111 19.4843C15.8601 19.2333 15.5491 19.1078 15.1781 19.1078C14.8071 19.1078 14.4961 19.2333 14.2451 19.4843C13.9941 19.7353 13.8686 20.0463 13.8686 20.4174C13.8686 20.7884 13.9941 21.0994 14.2451 21.3504C14.4961 21.6014 14.8071 21.7269 15.1781 21.7269ZM23.69 27.6197C24.4102 27.6197 25.0268 27.3633 25.5397 26.8504C26.0526 26.3375 26.3091 25.7209 26.3091 25.0007C26.3091 24.2805 26.0526 23.6639 25.5397 23.151C25.0268 22.6381 24.4102 22.3817 23.69 22.3817C22.9698 22.3817 22.3532 22.6381 21.8403 23.151C21.3274 23.6639 21.071 24.2805 21.071 25.0007C21.071 25.7209 21.3274 26.3375 21.8403 26.8504C22.3532 27.3633 22.9698 27.6197 23.69 27.6197Z"
        fill="white"
      />
    </svg>
  );
};

const ProductExperienceLogoElevate = ({ chemoName }) => {
  const color = getMoodColors(chemoName).color;
  return (
    <svg width="26" height="26" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="25" cy="25" r="24.3515" fill="#FFC700" stroke="#FFC700" strokeWidth="1.29699" />
      <path
        d="M23.8091 16.6672V11.9053H26.19V16.6672H23.8091ZM31.7257 19.941L30.0888 18.3041L33.4222 14.8815L35.0888 16.5779L31.7257 19.941ZM33.3329 26.191V23.81H38.0948V26.191H33.3329ZM23.8091 38.0957V33.3338H26.19V38.0957H23.8091ZM18.2733 19.8815L14.8805 16.5779L16.5769 14.9112L19.94 18.2743L18.2733 19.8815ZM33.3924 35.1196L30.0888 31.6969L31.696 30.0898L35.0888 33.3636L33.3924 35.1196ZM11.9043 26.191V23.81H16.6662V26.191H11.9043ZM16.5769 35.1196L14.9102 33.4231L18.2436 30.0898L19.1067 30.8934L19.9698 31.7267L16.5769 35.1196ZM24.9995 32.1434C23.0154 32.1434 21.3289 31.4489 19.94 30.06C18.5511 28.6711 17.8567 26.9846 17.8567 25.0005C17.8567 23.0164 18.5511 21.3299 19.94 19.941C21.3289 18.5521 23.0154 17.8577 24.9995 17.8577C26.9837 17.8577 28.6702 18.5521 30.0591 19.941C31.4479 21.3299 32.1424 23.0164 32.1424 25.0005C32.1424 26.9846 31.4479 28.6711 30.0591 30.06C28.6702 31.4489 26.9837 32.1434 24.9995 32.1434Z"
        fill="white"
      />
    </svg>
  );
};

const ProductExperienceLogosMap = {
  ELEVATE: <ProductExperienceLogoElevate chemoName={'ELEVATE'} />,
  MOTIVATE: <ProductExperienceLogoMotivate chemoName={'MOTIVATE'} />,
  INDULGE: <ProductExperienceLogoIndulge chemoName={'INDULGE'} />,
  RECOVER: <ProductExperienceLogoRecover chemoName={'RECOVER'} />,
  RELAX: <ProductExperienceLogoRelax chemoName={'RELAX'} />,
  SLEEP: <ProductExperienceLogoSleep chemoName={'SLEEP'} />
};

const ProductExperienceLogoSVG = ({ chemoName }) => {
  return ProductExperienceLogosMap[chemoName] || <ProductExperienceLogoSleep />;
};

export default function BaseballCardBack({ product }) {
  console.log({ product });
  return (
    <Box
      sx={{
        width: '240px',
        height: '336px'
      }}
    >
      <Grid container justifyContent="space-around" alignItems="center">
        <Grid
          item
          xs={12}
          sx={{
            backgroundImage: `url(${CardBackBackground})`,
            height: '40px'
          }}
        >
          <Box
            sx={{
              marginTop: '7px',
              marginLeft: '6px',
              display: 'flex'
            }}
          >
            <ProductExperienceLogoSVG chemoName={product.composition.chemoName} />
            <Box
              sx={{
                marginLeft: '8px'
              }}
            >
              <Typography
                color="#fff"
                fontFamily="Space Grotesk"
                fontSize={product.thumbnail.name.trim().split(/\s+/).length >= 4 ? '9px' : '12px'}
                fontWeight={700}
              >
                {product.thumbnail.name}
              </Typography>
              <Typography
                color="#fff"
                fontFamily="Inter"
                fontSize={product.thumbnail.name.trim().split(/\s+/).length >= 4 ? '7px' : '8px'}
                fontWeight={400}
                marginTop={'0px'}
              >
                {product.thumbnail.offeredBy}
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            backgroundColor: '#000',
            height: '15px',
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '10px'
          }}
        >
          <Typography color="#fff" fontFamily="Inter" fontSize={'8px'} fontWeight={500} marginTop={'0px'} lineHeight={'7px'}>
            {product?.composition?.cannabinoids?.productType}
          </Typography>
        </Grid>

        <Grid item xs={6} sx={{ padding: '8px', paddingTop: '4px' }}>
          <MoodsComponent
            chemoName={product.experience.logo.category}
            data={product.experience.filters}
            isReport={false}
            isMiniReport={true}
          />
        </Grid>

        <Divider orientation="vertical" flexItem sx={{ mr: '-1px', borderColor: '#F1EEEB', marginTop: 1, marginBottom: 1, padding: 0 }} />

        <Grid item xs={6} sx={{ padding: '8px' }}>
          <Cannabinoids
            cannabinoids={product.composition.cannabinoids}
            isReport={false}
            isMiniReport={true}
            chemoName={product.composition.chemoName}
          />
          <Terpenes
            terpenesData={product.composition.terpenes}
            isReport={false}
            isMiniReport={true}
            chemoName={product.composition.chemoName}
          />
        </Grid>

        <Divider flexItem sx={{ width: '200px', borderColor: '#F1EEEB' }} />

        <Grid item xs={4}>
          <Typography
            display="flex"
            alignItems="center"
            flexDirection={'column'}
            fontFamily="Inter"
            fontSize={'7px'}
            fontWeight={'600'}
            mt={0.5}
          >
            Wellness Uses
          </Typography>
          {product.experience.medicinalUses.map((use, index) => (
            <Box key={index} display="flex" alignItems="center" flexDirection={'column'}>
              <MedicinalLogosSVG
                medicinalName={use?.label?.toUpperCase()}
                chemoName={product.composition.chemoName}
                width={'20'}
                height={'20'}
              />

              <Typography variant="subtitle1" fontFamily={'Inter'} fontWeight={500} fontSize={'7px'} mb={0.3}>
                {use?.label?.slice(0, 1)?.toUpperCase() + use?.label?.slice(1)?.toLowerCase()}
              </Typography>
            </Box>
          ))}
        </Grid>

        <Divider orientation="vertical" flexItem sx={{ mr: '-1px', borderColor: '#F1EEEB', marginTop: 0, marginBottom: 0, padding: 0 }} />

        <Grid item xs={4}>
          <Typography
            display="flex"
            alignItems="center"
            flexDirection={'column'}
            fontFamily="Inter"
            fontSize={'7px'}
            fontWeight={'600'}
            mt={0.5}
          >
            Activity Pairings
          </Typography>
          {product?.experience?.activityPairings.map((activity, index) => (
            <Box key={index} display="flex" alignItems="center" flexDirection={'column'} style={{ minHeight: '33.39px' }}>
              {!!activity.label && (
                <>
                  <ActivitiesLogosSVG
                    activityName={activity?.label?.toUpperCase()}
                    chemoName={product.composition.chemoName}
                    width={'20'}
                    height={'20'}
                  />

                  <Typography variant="subtitle1" fontFamily={'Inter'} fontWeight={500} fontSize={'7px'} mb={0.3}>
                    {activity?.label?.slice(0, 1)?.toUpperCase() + activity?.label?.slice(1)?.toLowerCase()}
                  </Typography>
                </>
              )}
            </Box>
          ))}
        </Grid>

        <Divider orientation="vertical" flexItem sx={{ mr: '-1px', borderColor: '#F1EEEB', marginTop: 0, marginBottom: 0, padding: 0 }} />

        <Grid item xs={4}>
          <Typography
            display="flex"
            alignItems="center"
            flexDirection={'column'}
            fontFamily="Inter"
            fontSize={'7px'}
            fontWeight={'600'}
            mt={0.5}
          >
            Aroma Profile
          </Typography>

          <Box display="flex" alignItems="center" flexDirection={'column'}>
            <AromaLogosSVG aromaName={product.composition.terpenes.profile.profile1.name?.toUpperCase()} width={'20'} height={'20'} />
            <Typography display="flex" variant="subtitle1" fontSize={'7px'} mb={0.3}>
              {product.composition.terpenes.profile?.profile1?.name?.slice(0, 1)?.toUpperCase() +
                product.composition.terpenes.profile.profile1?.name.slice(1)}
            </Typography>

            <AromaLogosSVG aromaName={product.composition.terpenes.profile.profile2?.name?.toUpperCase()} width={'20'} height={'20'} />
            <Typography display="flex" variant="subtitle1" fontSize={'7px'} mb={0.3}>
              {product.composition.terpenes.profile?.profile2?.name?.slice(0, 1)?.toUpperCase() +
                product.composition.terpenes.profile.profile2?.name?.slice(1)}
            </Typography>

            <AromaLogosSVG aromaName={product.composition.terpenes.profile.profile3?.name?.toUpperCase()} width={'20'} height={'20'} />
            <Typography display="flex" variant="subtitle1" fontSize={'7px'} mb={0.3}>
              {product.composition.terpenes.profile?.profile3?.name?.slice(0, 1)?.toUpperCase() +
                product.composition.terpenes.profile.profile3?.name?.slice(1)}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} mt={1} ml={1} mb={0.5}>
          <Typography fontFamily="Inter" fontSize="5px" color="#767676">
            &copy; Powerleaf 2024 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Graphs compare this product to other products of the same type.
          </Typography>
        </Grid>

        <Grid
          container
          item
          xs={12}
          alignItems="center"
          sx={{
            backgroundImage: `url(${CardBackBottomBackground})`,
            height: '20px'
          }}
        >
          <Grid item xs={6}>
            <Logo2 height="10px" width="56px" style={{ marginLeft: '7px' }} />
          </Grid>

          <Grid item xs={6} display="flex" justifyContent={'center'} alignItems={'center'}>
            <Typography
              display="flex"
              fontSize="4.5px"
              color="#fff"
              letterSpacing={0.8}
              width="100%"
              fontWeight={50}
              style={{ marginLeft: '22px', marginTop: '1px' }}
            >
              ANALYZED BY
            </Typography>
            <ReportAnaylzedByLogo display="flex" height="7px" style={{ marginLeft: '-16px' }} />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
