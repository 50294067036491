import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Components
import ProductsList from '../../../components/lists/productsList';
import OverAllFilters from '../../../components/shopByFilters/selectedFilters';
import SortComponent from '../../../components/shopByFilters/sort';
import ShopByProductTypeFilters from './shopByProductType';

// Consts
import { productTypeConsts } from '../../../consts/shopByProductType';

// Hooks
import useFetchProducts from '../../../hooks/useFetchProducts';

// Actions
import { fetchProductsLoadingAction, updateFiltersAction } from '../../../store/actions/shopByActions';

// Selectors
import { selectSelectedFilters } from '../../../store/selectors/shopBySelectors';

const ShopByProductType = () => {
  // Dispatch
  const dispatch = useDispatch();

  // Navigate
  const navigate = useNavigate();

  //Selectors
  const selectedFilters = useSelector(selectSelectedFilters);

  // State
  const initialSelectedProductType = selectedFilters['Product Type'] ? getNameByTitle(selectedFilters['Product Type']) : 'Flower';
  const [selectedProduct, setSelectedProduct] = useState(initialSelectedProductType);

  // Hooks
  const { loading, products } = useFetchProducts();

  console.log({selectedProduct})
  // Handlers
  const handleSelectProduct = (productName) => {
    setSelectedProduct(productName);
  };

  // Handlers
  function getTitleByName(selectedProduct) {
    const type = productTypeConsts.types.find((t) => t.name === selectedProduct);
    return type ? type.name : 'All';
  }

  function getNameByTitle(name) {
    const type = productTypeConsts.types.find((t) => t.name === name);
    return type ? type.name : 0;
  }

  const handleProductClick = (id) => {
    navigate(`/product/${id}`);
  };

  // UseEffect
  useEffect(() => {
    if (selectedProduct !== null) {
      const updatedFilters = {
        ...selectedFilters,
        page: 1,
        pageSize: 10,
        'Product Type': getTitleByName(selectedProduct)
      };
      dispatch(fetchProductsLoadingAction(true));
      dispatch(updateFiltersAction(updatedFilters));
    }
  }, [selectedProduct]);

  useEffect(() => {
    if (selectedFilters['Product Type']) {
      setSelectedProduct(getNameByTitle(selectedFilters['Product Type']));
    } else {
      setSelectedProduct(initialSelectedProductType);
    }
  }, [selectedFilters]);

  return (
    <Grid container justifyContent="center" alignItems={'center'}>
      <ShopByProductTypeFilters selectedProduct={selectedProduct} handleSelectProduct={handleSelectProduct} filters={productTypeConsts} />
      <Grid item container display={'flex'} direction={'column'} sx={{ maxWidth: '1600px' }}>
        <OverAllFilters />
        <SortComponent />
        <ProductsList loading={loading} products={products} onClick={handleProductClick} />
      </Grid>
    </Grid>
  );
};

export default ShopByProductType;
