import AddIcon from '@mui/icons-material/Add';
import ImageIcon from '@mui/icons-material/Image';
import { Box, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Fab from '@mui/material/Fab';
import React, { useState } from 'react';
import { ReactComponent as ElevateeIcon } from '../../assets/NewProductCardIcons/Elevate.svg';
import { ReactComponent as IndulgeIcon } from '../../assets/NewProductCardIcons/Indulge.svg';
import { ReactComponent as MotivateIcon } from '../../assets/NewProductCardIcons/Motivate.svg';
import { ReactComponent as RecoverIcon } from '../../assets/NewProductCardIcons/Recover.svg';
import { ReactComponent as RelaxIcon } from '../../assets/NewProductCardIcons/Relax.svg';
import { ReactComponent as SleepIcon } from '../../assets/NewProductCardIcons/Sleep.svg';

// Styled components
import { IconContainer, ProductBrandType, ProductName, ProductPrice, imageAreaHeight } from './styledComponents';

// Utils
import { findIcon } from '../../utils/shopByUtils';

import { addToCart, setCartDialog, setCartType } from '../../store/checkout/actions/checkoutActions';
import { selectCartType } from '../../store/checkout/selectors/checkoutSelectors';
import { fetchProductData, fetchProductDataSuccess } from '../../store/productDetails/actions/productActions';
import { selectPurchaseTypeFilterState } from '../../store/selectors/shopBySelectors';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

// Consts
import { getMoodColors } from '../../config/consts';
import { activityIcons, medicinalIcons, moodIcons } from '../../consts/filtersConsts';
import { parseImg } from '../../utils/parseImg';

const GetMoodIcon = ({ type }) => {
  type = type?.toUpperCase();
  if (type === 'SLEEP') {
    return <SleepIcon width={'95px'} />;
  }
  if (type === 'INDULGE') {
    return <IndulgeIcon width={'95px'} />;
  }
  if (type === 'MOTIVATE') {
    return <MotivateIcon width={'95px'} />;
  }
  if (type === 'ELEVATE') {
    return <ElevateeIcon width={'95px'} />;
  }
  if (type === 'RELAX') {
    return <RelaxIcon width={'95px'} />;
  }
  if (type === 'RECOVER') {
    return <RecoverIcon width={'95px'} />;
  }
};

const ProductCard = ({ product, onClick }) => {
  console.log({ product });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [imageLoaded, setImageLoaded] = useState(true);

  const cartType = useSelector(selectCartType);
  const purchaseTypeState = useSelector(selectPurchaseTypeFilterState);

  // Consts
  const moodIcon = product.rightMood ? findIcon('mood', moodIcons, product.rightMood) : null;
  const activityIcon =
    product.activity && product.activity.activity1 ? findIcon('activity', activityIcons, product.activity.activity1) : null;
  const medicinalIcon = product.medicinal && product.medicinal.app1 ? findIcon('medicinal', medicinalIcons, product.medicinal.app1) : null;

  const borderColor = getMoodColors(product.rightMood ? product.rightMood.toUpperCase() : 'DEFAULT').color;

  const handleAddToCart = () => {
    dispatch(fetchProductData(product.id));

    console.log({ product });
    var p = {
      ...product,
      productId: product.id,
      quantitySelection: {
        weight: product.flowerEquiv,
        price: product.price,
        quantity: 1
      },
      thumbnail: {
        name: product.product,
        picture: product.imageURL
      },
      composition: {
        cannabinoids: {
          productType: product.productType
        }
      }
    };

    if (cartType === null) {
      dispatch(setCartType(purchaseTypeState));
      dispatch(addToCart(p));
      navigate('/checkout');
    } else if (cartType === purchaseTypeState) {
      dispatch(addToCart(p));
      navigate('/checkout');
    } else {
      dispatch(fetchProductDataSuccess(p));
      dispatch(setCartDialog(true));
    }
  };

  return (
    <Card
      sx={{ width: 263, height: 320, borderBottom: `6px solid ${borderColor}` }}
      onClick={() => {
        onClick(product.id);
      }}
    >
      <CardActionArea>
        {product.imageURL && imageLoaded ? (
          <>
            <Fab
              size="small"
              color="#fff"
              aria-label="add"
              style={{ zIndex: 15, position: 'absolute', left: '82%', top: '1%' }}
              onClick={(event) => {
                handleAddToCart();
                event.stopPropagation();
              }}
            >
              <AddIcon onClick={handleAddToCart} />
            </Fab>
            <CardMedia
              component="img"
              height={imageAreaHeight}
              src={parseImg(product.imageURL)}
              alt={product.id}
              style={{ width: '100%', height: imageAreaHeight, objectFit: 'cover' }}
              onError={() => setImageLoaded(false)}
            />
          </>
        ) : (
          <>
            <Fab
              size="small"
              color="#fff"
              aria-label="add"
              style={{ zIndex: 15, position: 'absolute', left: '82%', top: '1%' }}
              onClick={(event) => {
                handleAddToCart();
                event.stopPropagation();
              }}
            >
              <AddIcon onClick={handleAddToCart} />
            </Fab>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height={imageAreaHeight}
              sx={{ width: '100%', backgroundColor: '#f0f0f0' }}
            >
              <ImageIcon style={{ fontSize: '300%', color: '#ccc' }} />
            </Box>
          </>
        )}
        <CardContent>
          <ProductName gutterBottom variant="h5" component="div">
            {product.product}
          </ProductName>
          {product?.brand ? (
            <ProductBrandType variant="h6">{[product.brand, product.productType].join(' | ')}</ProductBrandType>
          ) : (
            <ProductBrandType variant="h6">{product.productType}</ProductBrandType>
          )}
          <ProductPrice variant="h4">${product?.price?.toFixed(2)}</ProductPrice>
          <IconContainer container spacing={0} gap={0} m={0} p={0}>
            {/* {moodIcon && (
              <Grid item xs={4}>
                <Button size="small" title="Mood">
                  <img src={moodIcon} alt="Mood Icon" />
                </Button>
              </Grid>
            )} */}
            <Grid item xs={5}>
              <Button size="small" title="Mood">
                <GetMoodIcon type={product.rightMood} />
              </Button>
            </Grid>
            {activityIcon && (
              <Grid item xs={3} style={{ marginRight: '5px', marginLeft: '5px' }}>
                <Button size="small" title="Activity">
                  <img src={activityIcon} alt="Activity Icon" />
                </Button>
              </Grid>
            )}
            {medicinalIcon && (
              <Grid item xs={3}>
                <Button size="small" title="Medicinal">
                  <img src={medicinalIcon} alt="Medicinal Icon" />
                </Button>
              </Grid>
            )}
          </IconContainer>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ProductCard;
