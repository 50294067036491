import { Box, Grid, Paper, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import ScienceSubsection from './scienceSubsection';

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '36px',
  fontWeight: '700',
  fontFamily: 'Space Grotesk',
  width: '100%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    fontSize: '24px'
  }
}));

const SectionSubtitle = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  fontFamily: 'Space Grotesk',
  fontStyle: 'normal',
  fontWeight: '500',
  [theme.breakpoints.down('md')]: {
    fontSize: '16px'
  }
}));

const SectionDescription = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '500',
  [theme.breakpoints.down('md')]: {
    fontSize: '14px'
  }
}));

const StyledSectionContainer = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  width: '100%',
  maxWidth: '750px',
  margin: 'auto'
}));

const StyledSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  maxWidth: '930px',
  margin: '0 auto',
  width: '100%',
  padding: theme.spacing(2)
}));

const YouTubeIframeContainer = styled(Box)(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
  margin: 'auto',
  zIndex: 2,

  marginTop: '25px',

  width: '100%',
  '&::before': {
    content: '""',
    display: 'block',
    paddingTop: '56.25%'
  },
  '& > iframe': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: '100%'
  }
}));

const InfoCard = styled(Paper)({
  padding: '1em',
  textAlign: 'center'
});

const Media = () => {
  const theme = useTheme();
  const isMdOrMore = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <StyledSection padding={isMdOrMore ? 4 : 2}>
      {/* <img src={LogoSmall} alt={"POWERLEAF"} style={{ width: "50px", marginBottom: 10 }} /> */}
      <StyledSectionContainer>
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12}>
            <SectionTitle>CURATE YOUR EXPERIENCE </SectionTitle>
            <SectionSubtitle variant="subtitle1">
              Cannabis consumers are using products that don&apos;t pair well with their needs.
            </SectionSubtitle>
            <SectionSubtitle variant="subtitle1">We&apos;ll match the best available product on the menu </SectionSubtitle>
            <SectionSubtitle variant="subtitle1">with the experience you actually want to have.</SectionSubtitle>
          </Grid>

          {/* {!isMdOrMore && (
            <Grid item xs={12}>
              <SectionDescription variant="subtitle1" display="inline" style={{ fontSize: '16px', fontWeight: '700', fontFamily: 'Inter' }}>
                Blue Dream? Indica? Sativa? Hybrid? None of it matters.
              </SectionDescription>
              <SectionDescription variant="subtitle1" display="inline" style={{ fontSize: '16px', fontFamily: 'Inter', fontWeight: '400' }}>
                We use laboratory testing and science to connect you to your ideal product experience.
              </SectionDescription>
            </Grid>
          )} */}

          <YouTubeIframeContainer>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/-x8GFWL-Huk?si=_Xc2ExkNTvfoZgw4&rel=0&autoplay=0&loop=0&autopause=1&playlist=-x8GFWL-Huk"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; autoplay;"
              referrerPolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </YouTubeIframeContainer>

          <Grid container spacing={2} mt={3} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
            {/* {isMdOrMore && (
              <Grid item md={12} mr={4}>
                <SectionDescription
                  variant="subtitle1"
                  display="inline"
                  style={{ fontSize: '16px', fontWeight: '700', fontFamily: 'Inter' }}
                >
                  Blue Dream? Indica? Sativa? Hybrid? None of it matters.&nbsp;
                </SectionDescription>
                <SectionDescription variant="subtitle1" display="inline" style={{ fontSize: '16px', fontFamily: 'Inter' }}>
                  We use laboratory testing and science to connect you to your ideal product experience.
                </SectionDescription>
              </Grid>
            )} */}
            {/* <Grid item xs={12} md={isMdOrMore ? 5 : 12}>
              <CustomButton title={'Learn More'} onClick={() => { }} />
            </Grid> */}
          </Grid>
        </Grid>
      </StyledSectionContainer>

      <ScienceSubsection />
    </StyledSection>
  );
};

export default Media;
