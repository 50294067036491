import { Box, Chip, FormControl, Grid, MenuItem, Select, Typography, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Close } from '../../assets/images/icons/buttons/close.svg';

// Consts
import { initialSelectedFilters } from '../../consts/filtersConsts';

// Components
import { useFilterSelection } from '../../hooks/useFilterSelection';
import FiltersDrawer from './filtersDrawer';

// Selectors
import { selectFilters, selectSelectedFilters } from '../../store/selectors/shopBySelectors';

// Actions
import { fetchProductsLoadingAction, updateFiltersAction } from '../../store/actions/shopByActions';

const SortByFormControl = styled(FormControl)`
  & .MuiInputBase-root {
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  & .MuiSelect-select {
    display: flex;
    align-items: center;
  }

  & .MuiSelect-icon {
    color: var(--Neutrals-Black, #000);
  }
`;

const SortByText = styled(Typography)`
  color: var(--Neutrals-Black, #000);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

const FilterChip = ({ icon, onToggle, startIcon, heading, tag, handleDelete, handleRevealProducts }) => {
  const dispatch = useDispatch();

  //Selectors
  const filterCategories = useSelector(selectFilters);
  const selectedFilters = useSelector(selectSelectedFilters);

  // States
  const [dropDownValue, setDropDownValue] = useState(selectedFilters['Product Type'] ? selectedFilters['Product Type'] : 'All');
  const [purchaseTypeDropDownValue, setPurchaseTypeDropDownValue] = useState(
    selectedFilters['Purchase Type'] ? selectedFilters['Purchase Type'] : 'adult'
  );

  // Handlers
  const handleChange = (event) => {
    setDropDownValue(event.target.value);
    const updatedFilters = {
      ...selectedFilters,
      page: 1,
      pageSize: 10,
      'Product Type': event.target.value
    };
    dispatch(fetchProductsLoadingAction(true));
    dispatch(updateFiltersAction(updatedFilters));
  };

  const handlePurchaseTypeChange = (event) => {
    setPurchaseTypeDropDownValue(event.target.value);
    const updatedFilters = {
      ...selectedFilters,
      page: 1,
      pageSize: 10,
      'Purchase Type': event.target.value
    };
    dispatch(fetchProductsLoadingAction(true));
    dispatch(updateFiltersAction(updatedFilters));
  };

  const handleRemoveFilter = () => {
    handleDelete(heading);
    // handleRevealProducts();
  };

  useEffect(() => {}, [selectedFilters]);

  useEffect(() => {
    setDropDownValue(selectedFilters['Product Type']);
  }, [selectedFilters['Product Type']]);

  const labelContent = heading ? (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <span
        style={{
          fontFamily: 'Inter',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: '700',
          lineHeight: '16px'
        }}
      >
        {heading}
      </span>
      {tag && (
        <>
          <span style={{ fontFamily: 'Inter', fontSize: '12px', fontStyle: 'normal', fontWeight: '400', lineHeight: '16px' }}>:&nbsp;</span>
          <span
            style={{
              fontFamily: 'Inter',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '16px'
            }}
          >
            {tag}
          </span>
          {heading !== 'Purchase Type' && heading !== 'Product Type' && (
            <>
              <span style={{ fontFamily: 'Inter', fontSize: '12px', fontStyle: 'normal', fontWeight: '400', lineHeight: '16px' }}>
                &nbsp;&nbsp;
              </span>
              <Close
                style={{ width: '14px', height: 'auto', marginLeft: '5px' }}
                onClick={() => {
                  handleRemoveFilter();
                }}
              />
            </>
          )}
        </>
      )}
    </div>
  ) : null;

  return (
    <>
      {heading === 'Product Type' ? (
        <>
          <Box
            display="flex"
            alignItems="center"
            sx={{
              width: 'auto',
              height: '28px',
              border: '2px solid #000',
              borderRadius: '4px'
            }}
          >
            <SortByFormControl size="small">
              <Select
                sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
                value={dropDownValue}
                onChange={handleChange}
              >
                <MenuItem key={'All'} value={'All'}>
                  <Typography
                    fontFamily={'Inter'}
                    fontSize={'12px'}
                    fontStyle={'normal'}
                    fontWeight={'700'}
                    lineHeight={'16px'}
                    display={'inline'}
                  >
                    Product Type: &nbsp;
                  </Typography>
                  <SortByText>All</SortByText>
                </MenuItem>
                {filterCategories?.productTypes?.map((category, key) => {
                  return (
                    <MenuItem key={key} value={category}>
                      <Typography
                        fontFamily={'Inter'}
                        fontSize={'12px'}
                        fontStyle={'normal'}
                        fontWeight={'700'}
                        lineHeight={'16px'}
                        display={'inline'}
                      >
                        Product Type: &nbsp;
                      </Typography>
                      <SortByText>{category}</SortByText>
                    </MenuItem>
                  );
                })}
              </Select>
            </SortByFormControl>
          </Box>
        </>
      ) : heading === 'Purchase Type' ? (
        <>
          <Box
            display="flex"
            alignItems="center"
            sx={{
              width: 'auto',
              height: '28px',
              border: '2px solid #000',
              borderRadius: '4px'
            }}
          >
            <SortByFormControl size="small">
              <Select
                sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
                value={purchaseTypeDropDownValue}
                onChange={handlePurchaseTypeChange}
              >
                <MenuItem key={'adult'} value={'adult'}>
                  <Typography
                    fontFamily={'Inter'}
                    fontSize={'12px'}
                    fontStyle={'normal'}
                    fontWeight={'700'}
                    lineHeight={'16px'}
                    display={'inline'}
                  >
                    Purchase Type: &nbsp;
                  </Typography>
                  <SortByText>Adult</SortByText>
                </MenuItem>

                <MenuItem key={'medical'} value={'medical'}>
                  <Typography
                    fontFamily={'Inter'}
                    fontSize={'12px'}
                    fontStyle={'normal'}
                    fontWeight={'700'}
                    lineHeight={'16px'}
                    display={'inline'}
                  >
                    Purchase Type: &nbsp;
                  </Typography>
                  <SortByText>Medical</SortByText>
                </MenuItem>
              </Select>
            </SortByFormControl>
          </Box>
        </>
      ) : (
        <>
          <Chip
            icon={icon ? startIcon : null}
            label={labelContent}
            clickable
            onClick={onToggle}
            sx={{
              fontWeight: 'bold',
              borderColor: 'black',
              borderWidth: 2,
              display: 'flex',
              height: '28px',
              padding: '6px 10px',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              '& .MuiChip-label': {
                fontFamily: 'Inter',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: '700',
                lineHeight: '16px'
              }
            }}
            variant="outlined"
            color="default"
          />
        </>
      )}
    </>
  );
};

const Filters = ({ filters, onFilterToggle, handleDelete, handleRevealProducts }) => {
  return (
    <Box display={'flex'} gap={1} sx={{ backgroundColor: '#F1EEEB', padding: '20px', overflowX: 'auto' }}>
      {filters.map((filter, index) => {
        console.log({ filter });
        return (
          <FilterChip
            key={index}
            heading={filter.heading}
            tag={withCannabinoidsTextFixer(filter)}
            icon={filter.icon}
            startIcon={filter.startIcon}
            onToggle={filter.id === 'Filter' ? onFilterToggle : null}
            handleDelete={handleDelete}
            handleRevealProducts={handleRevealProducts}
          />
        );
      })}
    </Box>
  );
};

const withCannabinoidsTextFixer = (filter) => {
  if (filter.heading !== 'Cannabinoids') return filter.tag;

  if (filter.tag && filter.tag[filter.tag.length - 1] === 'A') {
    return filter.tag.slice(0, filter.tag.length - 1);
  }

  return filter.tag;
};

// Example of using the Filters component
const OverAllFilters = () => {
  const { handleDelete, handleRevealProducts, handleDeleteAndRefresh, selectedFilters } = useFilterSelection();

  // Selectors
  // const selectedFilters = useSelector(selectSelectedFilters);

  // States
  const [filters, setFilters] = useState([...initialSelectedFilters]);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleFilterToggle = (label) => {
    setDrawerOpen((prevState) => !prevState); // Toggle drawer open/close
  };

  // UseEffects
  useEffect(() => {
    const dynamicFilters = Object.entries(selectedFilters ? selectedFilters : {})
      .filter(([key, _]) => key !== 'page' && key !== 'pageSize' && key !== 'sort')
      .map(([key, value]) => ({
        heading: key,
        tag: Array.isArray(value) ? value.join(', ') : value
      }));
    setFilters([...initialSelectedFilters, ...dynamicFilters]);
  }, [selectedFilters]);

  return (
    <Grid
      item
      sx={{
        width: '100%',
        '*::-webkit-scrollbar': {
          width: '20px'
        },
        '*::-webkit-scrollbar-track': {
          backgroundColor: 'transparent'
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: '#d6dee1',
          borderRadius: '20px',
          border: '6px solid transparent',
          backgroundClip: 'content-box'
        },
        '*::-webkit-scrollbar-thumb:hover': {
          backgroundColor: '#a8bbbf'
        }
      }}
    >
      <Filters
        filters={filters}
        onFilterToggle={handleFilterToggle}
        handleDelete={handleDeleteAndRefresh}
        handleRevealProducts={handleRevealProducts}
      />
      <FiltersDrawer open={drawerOpen} onClose={() => setDrawerOpen(false)} />
    </Grid>
  );
};

export default OverAllFilters;
