import { Box, FormControlLabel, RadioGroup } from '@mui/material';
import React from 'react';

// Components
import CustomCheckbox from '../checkboxes/customCheckbox';
import CustomRadio from '../radioButtons/customRadioButton';

import { CustomSlider, StyledOptionsTypography, StyledSliderTypography } from './styledComponents';

const IconSelector = ({ icons, selectedIcon, onSelect }) => {
  return (
    <Box>
      {icons.map((icon) => (
        <button
          key={icon.id}
          onClick={() => onSelect(icon.name)}
          style={{ cursor: 'pointer', margin: '0 5px', background: 'none', border: 'none', padding: 0 }}
        >
          <img
            key={icon.id}
            src={selectedIcon === icon.name ? icon.selected : icon.unselected}
            alt={icon.name}
            style={{ cursor: 'pointer', margin: '0 5px' }}
          />
        </button>
      ))}
    </Box>
  );
};

const FiltersDrawerOptions = ({
  category,
  selectedFilters,
  handleRadioChange,
  handleCheckboxChange,
  handleIconSelect,
  handleSliderChange,
  handleMultipleSliderChange
}) => {
  console.log('category', category);
  console.log('selectedFilters', selectedFilters);
  switch (category.type) {
    case 'radio':
      if (category.options) {
        return (
          <RadioGroup value={selectedFilters[category.title] || ''} onChange={(event) => handleRadioChange(event, category.title)}>
            {category.options.map((option, index) => (
              <FormControlLabel
                key={index}
                value={option}
                control={<CustomRadio checked={selectedFilters[category.title] === option} />}
                label={<StyledOptionsTypography>{cannabinoidsTextFixer(category, option)}</StyledOptionsTypography>}
              />
            ))}
          </RadioGroup>
        );
      }
      return null;
    case 'checkbox':
      return (
        <FormControlLabel
          control={
            <CustomCheckbox
              checked={selectedFilters[category.title] === category.title || false}
              onChange={(event) => handleCheckboxChange(event, category.title)}
            />
          }
          label="Show Only PowerLeaf Analyzed Lab Tested Products"
        />
      );
    case 'icons':
      return (
        <IconSelector
          icons={category.icons}
          selectedIcon={selectedFilters[category.title]}
          onSelect={(iconName) => handleIconSelect(category.title, iconName)}
        />
      );
    case 'slider':
      return (
        <CustomSlider
          value={typeof selectedFilters[category.title] === 'number' ? selectedFilters[category.title] : 0}
          onChange={(event, newValue) => handleSliderChange(category.title, newValue)}
          aria-labelledby="input-slider"
        />
      );
    case 'multipleSlider':
      return category.sliders.map((slider, index) => (
        <Box key={slider.name}>
          <StyledSliderTypography id={`${slider.name}-slider`} gutterBottom>
            {slider.title}
          </StyledSliderTypography>
          <CustomSlider
            value={(selectedFilters[category.title] && selectedFilters[category.title][index]) || slider.defaultValue}
            onChange={(event, newValue) => handleMultipleSliderChange(category.title, newValue, index)}
            valueLabelDisplay="auto"
            aria-labelledby={`${slider.name}-slider`}
            min={slider.min}
            max={slider.max}
          />
        </Box>
      ));
    default:
      return null;
  }
};

const cannabinoidsTextFixer = (category, option) => {
  if ('title' in category === false) return option;
  if (category?.title !== 'Cannabinoids') return option;

  if (option && option[option.length - 1] === 'A') {
    return option.slice(0, option.length - 1);
  }

  return option;
};

export default FiltersDrawerOptions;
