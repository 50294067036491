import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { ActivitiesLogosSVG } from '../../../../utils/activitiesUtil';
import { MedicinalLogosSVG } from '../../../../utils/medicinalUtil';

const ActivityMedicine = ({ activities, uses, isReport = false, chemoName }) => {
  console.log({ activities });
  return (
    <Grid container spacing={0} mt={!isReport ? 5 : 0} justifyContent={isReport ? 'center' : 'flex-start'}>
      <Grid item xs={6} display={'flex'} justifyContent={isReport ? 'center' : 'start'}>
        <Box>
          <Typography variant="h5" fontFamily={"Inter"} fontWeight={'700'} fontSize={isReport ? '12px' : '16px'} gutterBottom>
            Activity Pairings
          </Typography>
          {activities.map((activity, index) => {
            return (
              <Box key={index} mb={isReport ? '20px' : 1} display="flex" alignItems="center" flexDirection={isReport ? 'row' : 'column'}>
                {
                  !!activity.label && (
                    <>
                      <ActivitiesLogosSVG activityName={activity?.label?.toUpperCase()} chemoName={chemoName} width={isReport ? '37' : '60'} height={isReport ? '37' : '60'} />
                      <Typography variant="subtitle1" fontWeight="500" fontFamily={"Inter"} fontSize={isReport ? '9px' : '12px'} ml={isReport ? '4px' : 0}>
                        {activity?.label?.slice(0, 1)?.toUpperCase() + activity?.label?.slice(1,)?.toLowerCase()}
                      </Typography>
                    </>
                  )
                }
              </Box>
            )
          })}
        </Box>
      </Grid>

      <Grid item xs={6} display={'flex'} justifyContent={isReport ? 'center' : 'start'}>
        <Box>
          <Typography variant="h5" fontSize={isReport ? '12px' : '16px'} fontFamily={"Inter"} fontWeight={'700'} gutterBottom>
            Wellness Uses
          </Typography>
          {uses.map((use, index) => (
            <Box key={index} mb={isReport ? '20px' : 1} display="flex" alignItems="center" flexDirection={isReport ? 'row' : 'column'}>
              <MedicinalLogosSVG medicinalName={use?.label?.toUpperCase()} chemoName={chemoName} width={isReport ? '37' : '60'} height={isReport ? '37' : '60'} />
              <Typography variant="subtitle1" fontSize={isReport ? '9px' : '12px'} fontWeight={500} fontFamily={"Inter"} ml={isReport ? '4px' : 0}>
                {use?.label?.slice(0, 1)?.toUpperCase() + use?.label?.slice(1,)?.toLowerCase()}
              </Typography>
            </Box>
          ))}
        </Box>
      </Grid>
    </Grid>
  );
};

export default ActivityMedicine;
