const dateFormats = {
  'YYYY-MM-DD': 'YYYY-MM-DD',
  'MM/DD/YYYY': 'MM/DD/YYYY',
  'DD-MM-YYYY': 'DD-MM-YYYY',
  fullDate: 'fullDate',
  time: 'time',
  '12hour': '12hour',
  '24hour': '24hour'
};

function formatDate(dateString, format = 'default') {
  if (!dateString) return '';

  const date = new Date(dateString);

  // Helper function to add leading zeros for single digits
  const addLeadingZero = (number) => (number < 10 ? `0${number}` : number);

  const year = date.getUTCFullYear();
  const month = addLeadingZero(date.getUTCMonth() + 1); // Months are 0-indexed
  const day = addLeadingZero(date.getUTCDate());
  const hours24 = addLeadingZero(date.getUTCHours());
  const hours12 = addLeadingZero(date.getUTCHours() % 12 || 12); // Convert 24-hour to 12-hour
  const minutes = addLeadingZero(date.getUTCMinutes());
  const seconds = addLeadingZero(date.getUTCSeconds());
  const ampm = date.getUTCHours() >= 12 ? 'PM' : 'AM';

  // Default format: 'MMM DD, YYYY h:mm:ss a' (e.g., 'Sep 19, 2023 1:23:05 PM')
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const formats = {
    default: `${monthNames[date.getUTCMonth()]} ${day}, ${year} ${hours12}:${minutes}:${seconds} ${ampm}`,
    'YYYY-MM-DD': `${year}-${month}-${day}`,
    'MM/DD/YYYY': `${month}/${day}/${year}`,
    'DD-MM-YYYY': `${day}-${month}-${year}`,
    fullDate: `${['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][date.getUTCDay()]}, ${
      monthNames[date.getUTCMonth()]
    } ${day}, ${year}`,
    time: `${hours12}:${minutes}:${seconds} ${ampm}`,
    '12hour': `${hours12}:${minutes} ${ampm}`,
    '24hour': `${hours24}:${minutes}:${seconds}`
  };

  return formats[format] || formats.default;
}

export { dateFormats, formatDate };
